.employee-rates {
    &__outer-container {
        display: flex;
        flex-wrap: wrap;

        width: 100%;
        margin: 20px;

        &--mobile {
            display: flex;
            flex-direction: column;
        }
    }
}
