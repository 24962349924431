.single-truck-invoice {
    &__container {
        padding: 0 15px;

        &--mobile {
            padding: 10px;
        }
    }

    &__actions-container {
        display: flex;
        justify-content: flex-end;

        margin-right: 15px;
    }
}
