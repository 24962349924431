.reports {
    &__container {
        width: 100%;
        margin: 25px 50px;

        display: flex;
        flex-direction: column;

        &--mobile {
            @extend .reports__container;
            margin: 25px 0;

            align-items: center;
        }
    }

    &__tile-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 10px;

        &--mobile {
        }
    }

    &__report-container {
        width: 100%;
        margin-top: 25px;
    }
}
