.mobile-list {
    &__no-record {
        display: flex;
        justify-content: center;

        margin-top: 20px;
    }

    &__pagination {
        &__container {
            display: flex;
            justify-content: center;

            margin-top: 10px;
        }
    }
}
