.table-component {
    &__container {
        padding: 0 15px;
        &--mobile {
            padding: 10px;
        }
    }

    &__content {
        &__container {
            display: flex;
            flex-direction: column;

            margin: 25px 50px;

            &--mobile {
                @extend .table-component__content__container;
                margin: 25px 10px;
            }

            .table-search-and-filter__container {
                border-top: 2px solid $color-xpress-blue--faint;
                border-left: 2px solid $color-xpress-blue--faint;
                border-right: 2px solid $color-xpress-blue--faint;
            }
        }
    }

    &__action-buttons {
        &__container {
            display: flex;
            justify-content: space-between;

            margin-bottom: 25px;
        }
    }

    &__table {
        &__container {
            border-bottom: 2px solid $color-xpress-blue--faint;
            border-left: 2px solid $color-xpress-blue--faint;
            border-right: 2px solid $color-xpress-blue--faint;

            &--mobile {
                max-height: 60vh;
                overflow: auto;
            }
        }

        &__row {
            cursor: pointer;
        }
    }
}
