.rate-profiles {
    &__container {
        display: flex;

        width: 100%;
        &--mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;

            margin: 5px 20px;
        }
    }
}
