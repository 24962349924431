.notification-settings {
    &__outer-container {
        width: 100%;
        padding: 20px;
    }

    &__container {
        padding: 20px;

        border: 3px solid $color-xpress-blue;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-family: 'Varela Round';
        font-size: 28px;

        &--mobile {
            @extend .notification-settings__title;
            font-size: 22px;
        }
    }

    &__helper-text {
        display: flex;
        justify-content: center;

        font-style: italic;
    }

    &__table {
        display: flex;
        justify-content: center;

        width: 100%;
        margin-top: 30px;
        padding: 15px;
        box-sizing: border-box;
        border: 2px solid $color-xpress-blue--faint;

        &__inner-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            width: 100%;
        }
    }

    &__row {
        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 80%;
            border-top: 1px solid $color-xpress-blue--faint;

            &:first-child {
                border-top: none;
            }
        }

        &__cell {
            display: flex;
            justify-content: center;

            width: 100%;
            margin: 10px 0;
        }
    }

    &__error-text {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        font-family: 'Varela Round';
        font-size: 12px;
        font-style: italic;
        color: $bgcolor-error;
    }
}

// ANTD OVERRIDES
.notification-settings__table .ant-checkbox-disabled + span {
    color: black;
}

.notification-settings__table .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #fff !important;
    border-color: #1890ff !important;
}

.notification-settings__table .ant-checkbox-disabled .ant-checkbox-inner::after {
    border-color: #1890ff !important;
}
