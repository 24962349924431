.page-break {
    width: 100%;

    border-top: 2px solid #939393;
}

.input-label {
    font-family: 'Arial';
    font-size: 14px;
    color: #535353;
}

.input-label-bold {
    @extend .input-label;
    font-weight: bold;
}

.error-text {
    font-family: 'Arial';
    font-size: 14px;
    color: #e60000;
    font-style: italic;
}

.standard-button {
    width: fit-content;
    padding: 3px 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $color-xpress-blue;
    color: white;

    border-radius: 6px;

    font-family: 'Arial';
    font-size: 18px;

    cursor: pointer;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    &--double {
        @extend .standard-button;

        flex-direction: column;

        &--disabled {
            @extend .standard-button--double;

            background-color: $color-disabled;
            color: white;

            cursor: not-allowed;
        }
    }

    &--disabled {
        @extend .standard-button;
        background-color: $color-disabled;
        color: white;

        cursor: not-allowed;

        &--mobile {
            @extend .standard-button--disabled;
            width: 100%;
            padding: 6px 12px;
        }
    }

    &--empty {
        @extend .standard-button;

        background-color: transparent;
        color: $color-xpress-blue;

        &:hover {
            box-shadow: none;
        }

        &--mobile {
            @extend .standard-button--empty;
            width: 100%;
            padding: 6px 12px;
        }
    }

    &--mini {
        @extend .standard-button;

        font-size: 14px;
        padding: 2px 6px;
    }

    &--mobile {
        @extend .standard-button;
        width: 100%;
        padding: 6px 12px;
    }
}

.standard-button--double span:nth-child(2),
.standard-button--double--disabled span:nth-child(2) {
    font-size: 9px;
}

.form-header {
    display: flex;
    justify-content: center;

    background-color: $color-xpress-blue--faint;

    font-family: 'Varela Round';
    font-size: 32px;
    color: white;
}

.centered-container {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}
