.data-entry {
    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        padding: 20px 60px;

        &--mobile {
            @extend .data-entry__container;
            padding: 0 40px;
            box-sizing: border-box;
        }
    }

    &__quick-create {
        &__container {
            position: fixed;
            bottom: 30px;
            right: 40px;
        }
        &__button {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 60px;
            height: 60px;
            padding: 15px;

            background-color: $color-xpress-blue;
            color: white;
            border-radius: 50%;

            font-family: 'Varela Round';
            font-size: 48px;
            line-height: 48px;
            font-weight: 500;

            cursor: pointer;

            &:hover {
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                color: rgb(0, 195, 255);
            }
        }
    }
}

.quick-create-menu {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 10px 15px;
        border-radius: 6px;

        background-color: $color-xpress-blue;
        color: white;

        font-family: 'Arial';
        font-size: 18px;
    }

    &__cell {
        margin-bottom: 10px;
        cursor: pointer;

        &:hover {
            color: rgb(0, 195, 255);
        }

        &:nth-child(3) {
            margin-bottom: 0;
        }
    }
}

// ***** ANTD OVERRIDES *****
.ant-popover-arrow {
    display: none !important;
}

.ant-popover-inner-content {
    padding: 0 !important;
}
