.entity-form-container {
    &__content {
        &__container {
            margin: 25px 50px;

            &--mobile {
                margin: 25px 10px;
            }
        }

        &__form {
            &__container {
                width: 100%;
                margin-top: 25px;

                display: flex;
                flex-direction: column;
            }

            &__buttons {
                &__outer-container {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                }
                &__container {
                    width: 30%;

                    display: flex;
                    justify-content: space-between;

                    &--mobile {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }

        &__spinner {
            &__container {
                display: flex;
                justify-content: center;
                align-items: center;

                padding: 200px 0;
            }
        }
    }
}
