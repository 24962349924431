.new-user-sign-up {
    &__outer-container {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__container {
        width: 400px;

        padding: 40px;
        border-radius: 15px;

        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    &__title {
        font-family: 'Arial';
        font-weight: 400;
        font-size: 28px;
        color: #535353;
    }

    &__details {
        &__container {
            margin-top: 40px;
        }

        &__pending {
            display: flex;
            justify-content: center;

            margin-top: 15px;
        }
    }

    &__logo {
        &__container {
            height: 60px;
            width: 100%;

            display: flex;
            justify-content: center;
        }
    }
}
