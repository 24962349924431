.confirm-email-update {
    &__container {
        width: 95vw;
        height: 70vh;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__spinner {
        &__container {
            margin-top: 25px;
        }
    }

    &__logo {
        &__container {
            height: 100px;
            width: 100%;
            margin-top: 25px;

            display: flex;
            justify-content: center;
        }
    }
}
