.change-email {
    &__container {
        display: flex;
        justify-content: space-between;
    }

    &__button {
        &__container {
            width: 100%;
            margin-top: 30px;

            display: flex;
            justify-content: flex-end;
        }
    }
}
