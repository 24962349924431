.entity-header {
    &__container {
        width: 100%;

        display: flex;
        justify-content: space-between;

        &--left,
        &--right {
            display: flex;
            align-items: center;
        }

        &--left {
            &--mobile {
                @extend .entity-header__container--left;

                max-width: 80%;
            }
        }
    }

    &__logo {
        &__container {
            width: 100px;
            height: 100px;

            margin-right: 20px;
        }

        &__img {
            height: 100%;
        }
    }

    &__title {
        font-family: 'Varela Round';
        font-size: 36px;
        font-weight: 600;

        &--mobile {
            @extend .entity-header__title;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
