.rate-types {
    &__outer-container {
        width: 97%;
        display: flex;
    }

    &__container {
        width: 60%;
        margin: 20px 20px 60px;
        padding: 12px;
        box-sizing: border-box;

        border: 3px solid $color-xpress-blue;

        &--mobile {
            @extend .rate-types__container;
            width: 100%;
            margin: 20px 0;
        }
    }

    &__heading {
        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 100%;
            margin-bottom: 10px;
        }
        &__title {
            @extend .heading--smaller;
        }
    }
}
