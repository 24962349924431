.truck-invoice-template {
    &__container {
        margin-top: 50px;
        padding: 0 15px;

        &--mobile {
            padding: 10px;
        }
    }

    &__header {
        &__container {
            width: 100%;
            margin-bottom: 50px;

            display: flex;
            justify-content: space-between;
        }
    }

    &__billing-details,
    &__invoice-details {
        &__container {
            display: flex;
            flex-direction: column;
        }
    }

    &__invoice-details {
        &__container {
            align-items: flex-end;

            &--mobile {
                margin-top: 25px;
            }
        }
    }

    &__content {
        &__container {
            width: 100%;

            display: flex;
            flex-direction: column;

            &--mobile {
                @extend .truck-invoice-template__content__container;
                margin-top: 25px;
            }
        }
    }

    &__service {
        &__container {
            width: 100%;
            margin-bottom: 25px;

            display: flex;
            flex-direction: column;

            &--mobile {
                @extend .truck-invoice-template__service__container;
                margin-bottom: 25px;
            }
        }
    }

    &__service-invoice-line {
        display: flex;
        width: 100%;
        padding: 2px;

        &--mobile {
            @extend .truck-invoice-template__service-invoice-line;
        }
    }

    &__service-invoice-line-item {
        &--centered {
            display: flex;
            justify-content: center;
        }
    }

    &__footer {
        &__container {
            width: 100%;
            margin-top: 50px;

            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}
