.delivery-charge-report {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__data {
        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;

            width: 80%;
            margin-top: 25px;
            margin-bottom: 20px;
            padding: 5px 25px;

            border: 2px $color-disabled solid;
            border-radius: 6px;
        }
    }

    &__report-header {
        &__container {
            display: flex;
            justify-content: space-between;
            width: 100%;

            font-family: 'Varela Round';
            font-size: 20px;
        }
    }

    &__modal {
        &__container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            width: 100%;
        }

        &__line-break {
            &__container {
                width: 100%;
                height: 7px !important;
                margin: 15px 0 25px;

                font-size: 14px;
                line-height: 14px;
                text-align: center;

                border-bottom: 1px solid #535353;
            }

            &__text {
                padding: 0 10px;
                background-color: #fff;
                color: #535353;
            }
        }

        &__select-button {
            &__container {
                display: flex;
                justify-content: center;

                width: 100%;
                margin-top: 35px;
            }
        }

        &__report-select {
            &__container {
                display: flex;
                flex-direction: column;

                width: 100%;
            }

            &__options {
                display: flex;
                flex-direction: column;
                align-items: center;

                width: 100%;
            }

            &__option {
                margin-bottom: 10px;

                font-style: italic;
                text-decoration: underline;
                color: $color-xpress-blue;

                cursor: pointer;
            }
        }
    }
}
