.sidebar {
    height: calc(100vh - 70px);
    position: fixed;
    z-index: 5;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    transition: all 0.7s;

    &__content {
        height: 100%;
        padding-left: 20px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &--top,
        &--bottom {
            display: flex;
            flex-direction: column;
        }

        &--center {
            flex-grow: 1;
        }
    }

    &__component {
        &__container {
            display: flex;
            align-items: center;

            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        &__title {
            margin-left: 15px;

            font-family: 'Arial';
            font-weight: 700;
            font-size: 16px;
            color: #535353;
        }
    }
}
