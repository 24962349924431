.setting-configuration {
    &__outer-container {
        padding: 20px;
        display: flex;
        justify-content: center;
    }

    &__container {
        padding: 20px;
        width: 700px;

        border: 3px solid $color-xpress-blue;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-family: 'Varela Round';
        font-size: 28px;

        &--mobile {
            @extend .setting-configuration__title;
            font-size: 22px;
        }
    }

    &__button {
        display: flex;
        justify-content: flex-end;

        margin-top: 10px;
    }
}
