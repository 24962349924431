.audits {
    &__container {
        width: 100%;
        height: calc(100vh - 70px - 150px);
        padding: 0 15px;
        overflow-y: auto;

        &--mobile {
            padding: 10px;
        }
    }

    &__spinner {
        position: absolute;
        right: 80px;
        top: -45px;
        z-index: 10;
    }

    &__filters {
        &__container {
            display: flex;

            margin: 0 50px;
        }

        &__button {
            &__container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                position: relative;
                top: 15px;

                margin: 0 50px;
            }
        }

        &__filter {
            &__container {
                width: 20%;

                padding: 0 15px;
                box-sizing: border-box;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}
