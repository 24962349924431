.single-invoice-form {
    &__container {
        display: flex;
        flex-direction: column;

        margin: 25px 0 50px;
        padding: 10px;
        box-sizing: border-box;

        border: 2px solid rgba(0, 0, 0, 0.15);
    }

    &__invoice-details {
        &__container {
            display: flex;
            justify-content: space-between;

            width: 100%;

            &--mobile {
                @extend .single-invoice-form__invoice-details__container;
                flex-direction: column;
            }
        }

        &__invoice-services {
            &__container {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    &__billing-details {
        &__container {
            @extend .single-invoice-form__invoice-details__container;
            margin-top: 45px;

            &--inner {
                display: flex;
                flex-direction: column;

                width: 100%;
            }

            &--mobile {
                @extend .single-invoice-form__billing-details__container;
                flex-direction: column;
            }
        }

        &__bill-to {
            &__container {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                &--mobile {
                    @extend .single-invoice-form__billing-details__bill-to__container;
                    flex-direction: column;
                    align-items: flex-start;

                    margin-top: 15px;
                }
            }

            &__select {
                &--mobile {
                    width: 100%;
                }
            }
        }
    }

    &__quantities {
        &__container {
            display: flex;
            flex-direction: column;

            margin-top: 45px;
            &__line {
                @extend .single-invoice-form__invoice-details__container;
            }
        }
    }

    &__downtime {
        &__container {
            display: flex;
            flex-direction: column;

            min-width: 15%;
        }
    }

    &__downtime-inputs {
        &__container {
            display: flex;
            justify-content: space-between;
        }
    }

    &__profit {
        &__container {
            @extend .single-invoice-form__invoice-details__container;
            margin-top: 45px;

            width: 50%;
        }
    }

    &__save-invoice-button {
        &__container {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            &--mobile {
                @extend .single-invoice-form__save-invoice-button__container;
                margin-top: 15px;
            }
        }
    }
}
