.entity-tile {
    &__container {
        width: 300px;
        height: 300px;

        padding: 20px;
        margin-bottom: 10px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        border: 3px solid $color-xpress-blue;

        cursor: pointer;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }

        &--disabled {
            @extend .entity-tile__container;
            border: 3px solid $color-disabled;
            cursor: not-allowed;

            &--mobile {
                @extend .entity-tile__container--disabled;

                height: 200px;
                margin-bottom: 20px;
            }
        }

        &--mobile {
            @extend .entity-tile__container;

            height: 200px;
            margin-bottom: 20px;
        }
    }

    &__header {
        display: flex;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
    }

    &__logo {
        &__container {
            width: 100px;
            height: 100px;

            margin-right: 20px;

            &--disabled {
                @extend .entity-tile__logo__container;
                // Recolor to match $color-disabled
                // https://codepen.io/sosuke/pen/Pjoqqp
                filter: invert(83%) sepia(0%) saturate(1295%) hue-rotate(184deg) brightness(87%)
                    contrast(89%);
            }
        }

        &__img {
            height: 100%;
        }
    }

    &__title {
        margin-top: 15px;

        @extend .heading--smaller;

        &--disabled {
            @extend .entity-tile__title;
            color: $color-disabled;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 60px;
        height: 60px;
        padding: 15px;

        background-color: $color-xpress-blue;
        color: white;
        border-radius: 50%;

        font-family: 'Varela Round';
        font-size: 48px;
        line-height: 48px;
        font-weight: 500;

        cursor: pointer;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            color: rgb(0, 195, 255);
        }
    }
}
