.payroll-create {
    &__helper-text {
        margin-bottom: 20px;

        color: $color-disabled;
        font-style: italic;
        text-align: center;
    }
}

.payroll-single {
    &__outer-container {
        margin-left: 20px;
    }

    &__container {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 20px 20px 20px 0;
        box-sizing: border-box;
    }

    &__inner-container {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        border: 3px solid $color-xpress-blue;
    }

    &__header,
    &__sub-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-family: 'Varela Round';
        font-size: 28px;

        &--smaller {
            @extend .payroll-single__title;
            font-size: 22px;

            &--mobile {
                @extend .payroll-single__title;
                font-size: 16px;
            }
        }

        &--mobile {
            @extend .payroll-single__title;
            font-size: 22px;
        }
    }

    &__sub-title {
        @extend .payroll-single__title;
        font-size: 14px;
    }

    &__action-buttons {
        &__container {
            display: flex;
        }
    }

    &__table {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        width: 100%;
        margin-top: 30px;
        padding: 10px;
        box-sizing: border-box;

        border: 1px solid $color-xpress-blue--faint;

        &__header {
            display: flex;
            justify-content: space-between;

            width: 100%;
        }
    }

    &__generate {
        &__container {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            width: 100%;
            margin-top: 25px;
        }

        &__text {
            margin-right: 10px;
            color: red;
            font-style: italic;
        }
    }

    &__finalized {
        &__container {
            display: flex;
            align-items: center;
        }
    }
}

.payroll-trucks {
    &__container {
        display: flex;
        flex-direction: column;

        width: 100%;
    }

    &__row-container,
    &__partial-row-container {
        display: flex;

        width: 100%;
        margin-bottom: 3px;

        &--header {
            @extend .payroll-trucks__row-container;

            font-weight: bold;
            font-size: 16px;
        }
    }

    &__cell {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 25%;

        &--filler {
            @extend .payroll-trucks__cell;
            width: 75%;
        }
    }

    &__partial-cell {
        @extend .payroll-trucks__cell;

        width: 33%;
    }
}
