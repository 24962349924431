.invoices-form {
    &__container {
        width: 100%;
        margin: 15px 0;

        display: flex;
        flex-direction: column;
    }

    &__line {
        display: flex;
        justify-content: space-between;

        width: 100%;
    }

    &__info {
        &__container {
            display: flex;
            justify-content: center;

            margin-top: 40px;

            &--mobile {
                @extend .invoices-form__info__container;

                margin-top: 10px;
                font-size: 15px;
                font-weight: 400;
                text-align: center;
            }
        }
    }
}
