.customer-form {
    &__container {
        width: 100%;
        margin: 15px 0;

        display: flex;
        flex-direction: column;
    }

    &__organization-section,
    &__namesandphone-section,
    &__otherdetails-section,
    &__address-section {
        display: flex;
        justify-content: space-between;
    }

    &__organization-section {
        margin-bottom: 60px;
    }

    &__namesandphone-section {
        &--left {
            display: flex;
            justify-content: space-between;

            width: 59%;

            &--mobile {
                flex-direction: column;
                width: 100%;
            }
        }

        &--right {
            width: 39%;

            &--mobile {
                flex-direction: column;
                width: 100%;
            }
        }
    }

    &__otherdetails-section,
    &__emails-section {
        &--mobile {
            flex-direction: column;
        }

        &--left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            width: 40%;

            &--mobile {
                width: 100%;
            }
        }

        &__additional-email {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__otherdetails-section {
        &--right {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            width: 60%;
            margin-left: 10%;

            &--mobile {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                justify-content: space-between;
            }
        }

        &__switch {
            &__container {
                display: flex;
                align-items: flex-end;
                margin-bottom: 10px;

                &--mobile {
                    margin-bottom: 0;
                }
            }
        }
    }
}
