.invoices-table {
    &__container {
        width: 100%;
        padding: 0 15px;

        &--mobile {
            padding: 10px;
        }
    }

    &__invoice-modal {
        &__customer-select {
            margin-bottom: 15px;
        }
    }
}

.ant-picker-separator {
    color: rgba(0, 0, 0, 0) !important;
}
.ant-picker-range-separator:after {
    position: relative !important;
    top: -9px !important;

    content: 'to' !important;
    font-size: 16px;
    color: #535353;
}
