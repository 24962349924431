.simple-details-form {
    &__container {
        display: flex;
        flex-direction: column;
    }

    &__line {
        display: flex;
        justify-content: space-between;

        &--mobile {
            display: flex;
            flex-direction: column;
        }
    }
}
