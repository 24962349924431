html {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100%;
}

.App {
    height: 100%;
    width: 100%;
}

.outer-content {
    // Hardcoded height of navbar
    position: absolute;
    top: $navbar-height;

    width: 100%;

    display: flex;
    flex-direction: column;
}

.content {
    position: absolute;
    transition: all 0.7s;
    margin-top: 50px;
}

.mobile-page-title {
    font-family: 'Varela Round';
    font-size: 24px;
    color: white;
    background-color: $color-xpress-blue;
    width: max-content;
    padding: 0 15px;
    border-radius: 0 0 6px 6px;
    position: absolute;
    top: -51px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.ant-modal-body {
    max-height: 50vh;
    overflow: auto;
}
