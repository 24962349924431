.users {
    &__container {
        width: 100%;
        padding: 0 15px;

        &--mobile {
            padding: 10px;
        }
    }

    &__add-modal {
        &__roles {
            &__container {
                margin-top: 5px;
            }
        }

        &__role-options {
            &__container {
                display: flex;
                justify-content: center;
            }
        }
    }

    &__edit-modal {
        &__active {
            &__container {
                margin-top: 20px;

                display: flex;
            }
        }
    }
}
