.truck-form {
    &__container {
        width: 100%;
        margin: 15px 0;

        display: flex;
        flex-direction: column;

        &--mobile {
            @extend .truck-form__container;
            margin: 0;
        }
    }

    &__form-container {
        margin-top: 25px;
        padding: 10px;
        box-sizing: border-box;

        border: 2px solid rgba(0, 0, 0, 0.15);
    }

    &__loading-container {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        margin-top: 20px;
    }

    &__line {
        display: flex;
        justify-content: space-between;

        width: 100%;

        &--mobile {
            flex-direction: column;
        }
    }

    &__save-button {
        &__container {
            display: flex;
            justify-content: flex-end;

            margin-top: 30px;
        }
    }

    &__double-buttons-container {
        display: flex;

        &--mobile {
            display: flex;
            flex-direction: column;

            width: 100%;
        }
    }

    &__search-modal {
        &__container {
            display: flex;
            justify-content: space-between;

            &--mobile {
                @extend .truck-form__search-modal__container;
                flex-direction: column;
            }
        }
    }

    &__status-banner {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        padding: 10px 0;
        box-sizing: border-box;

        font-family: 'Arial';
        font-size: 14px;
        color: #ffffff;
    }
}
