.tdms-table {
    &__container {
        padding: 0 15px;

        &--mobile {
            padding: 10px;
        }
    }

    &__modal {
        &__special-pay-switch {
            &__container {
                display: flex;
                justify-content: space-between;

                margin-top: 15px;
            }
        }
    }
}
