.todo-list {
    &__container {
        padding: 20px 30px 0;
        width: 100%;

        display: flex;
        justify-content: center;

        &--mobile {
            @extend .todo-list__container;
            padding: 10px;
        }
    }

    &__collapse-container {
        width: 100%;

        background-color: $color-xpress-blue;
        border: 1px solid $color-xpress-blue--faint;
        border-radius: 4px;

        font-family: 'Arial';
        font-size: 18px;
        color: #fff !important;
    }

    &__incomplete-entities,
    &__invoices-for-approval,
    &__truck-supplemental-pay,
    &__failed-to-send-invoices {
        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;

            width: 100%;

            &--mobile {
                text-align: justify;
            }
        }
    }

    &__truck-supplemental-pay {
        &__modal {
            &__container {
                display: flex;
                flex-direction: column;
                align-items: center;

                width: 100%;
            }

            &__info {
                display: flex;
                flex-direction: column;

                width: 100%;

                &--section {
                    display: flex;
                    justify-content: space-between;

                    margin-bottom: 10px;
                }
            }

            &__input {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }
    }
}

.ant-collapse-header,
.ant-badge {
    color: white !important;
    font-size: 18px;
}
