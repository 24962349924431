.dashboard {
    &__container {
        padding: 0 15px;

        &--mobile {
            padding: 10px;
        }
    }

    &__payroll-report {
        &__container {
            display: flex;
            justify-content: center;
        }
    }
}
