.payroll-report {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__data {
        &__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            width: 95%;
            margin-top: 50px;
            margin-bottom: 20px;
            padding: 5px;

            border: 2px $color-disabled solid;
            border-radius: 6px;
        }
    }

    &__table {
        &__container {
            width: 95%;
            margin-bottom: 25px;

            border: 2px $color-disabled solid;
            border-radius: 6px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            font-size: 18px;
        }
    }
}
