.report-tiles {
    &__tile {
        &__container {
            width: 300px;
            height: 100px;

            padding: 20px;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            border: 3px solid $color-xpress-blue;

            cursor: pointer;

            &:hover {
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            }

            &--mobile {
                @extend .report-tiles__tile__container;
                margin-bottom: 10px;
            }
        }

        &__title {
            @extend .heading--smaller;
            margin-bottom: -50px;
        }

        &__icon {
            width: 70px;
            margin-bottom: -20px;
            margin-left: -200px;
            opacity: 0.2;
        }
    }
}
