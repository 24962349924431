.customer-invoice-template {
    &__container {
        margin-top: 50px;
        padding: 0 15px;

        &--mobile {
            padding: 10px;
        }
    }

    &__header {
        &__container {
            width: 100%;
            margin-bottom: 50px;

            display: flex;
            justify-content: space-between;
        }
    }

    &__approve {
        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;

            @extend .heading--smaller;
            color: $bgcolor-success;

            border: 2px solid $bgcolor-success;

            cursor: pointer;

            &:hover {
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            }
        }
    }

    &__hold {
        &__container {
            @extend .customer-invoice-template__approve__container;
            color: $bgcolor-error;
            border: 2px solid $bgcolor-error;
        }
    }

    &__calculate {
        &__container {
            @extend .customer-invoice-template__approve__container;
            color: $bgcolor-warning;
            border: 2px solid $bgcolor-warning;
        }
    }

    &__billing-details,
    &__invoice-details {
        &__container {
            display: flex;
            flex-direction: column;
        }
    }

    &__invoice-details {
        &__container {
            align-items: flex-end;

            &--mobile {
                margin-top: 25px;
            }
        }
    }

    &__content {
        &__container {
            width: 100%;

            display: flex;
            flex-direction: column;

            &--mobile {
                @extend .truck-invoice-template__content__container;
                margin-top: 25px;
            }
        }

        &__warning-message {
            display: flex;
            justify-content: center;

            width: 100%;
            margin: 15px 0;

            font-family: 'Arial';
            font-size: 20px;
            font-style: italic;
            color: #ffa630;
        }
    }

    &__charges {
        &__container {
            width: 100%;
            margin-bottom: 25px;

            display: flex;
            flex-direction: column;

            &--mobile {
                @extend .customer-invoice-template__charges__container;
                margin-bottom: 25px;
            }
        }

        &__invoice-container {
            position: relative;
            margin-bottom: 20px;

            border: 1px solid $color-xpress-blue--faint;
        }

        &__view-invoice {
            position: absolute;

            right: 10px;
            top: calc(50% - 9px); // minus half of its height
        }
    }

    &__charges-invoice-line {
        display: flex;
        width: 100%;
        padding: 2px;

        &--mobile {
            @extend .customer-invoice-template__charges-invoice-line;
        }
    }

    &__charges-invoice-line-item {
        &--centered {
            display: flex;
            justify-content: center;
        }
    }

    &__footer {
        &__container {
            width: 100%;
            margin-top: 10px;

            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    &__totals {
        &__container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @extend .input-label;
            font-size: 20px !important;
            font-weight: bold !important
            ;
        }
    }
}
