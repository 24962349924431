.rates-view {
    &__container {
    }

    &__rate {
        &__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__title {
            font-family: 'Varela Round';
            font-size: 18px;
        }
    }
}
