.navbar {
    height: $navbar-height;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5;

    background-color: $color-xpress-blue;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__logo {
        &__container {
            height: 65px;
            margin-left: 10px;
        }
    }

    &__title {
        @extend .heading;
        color: white;
        text-transform: capitalize;
    }

    &__icons {
        &__container {
            display: flex;

            margin-right: 15px;
        }
    }

    &__navigation {
        &__component {
            &__container {
                display: flex;
                align-items: center;
            }

            &__title {
                margin: 0 15px;

                font-family: 'Arial';
                font-weight: 700;
                font-size: 16px;
                color: #535353;
            }
        }
    }

    // Each icon spacing
    .navbar__icons__container > div {
        margin: 0 15px;
    }
}
