.heading {
    font-family: 'Varela Round';
    font-size: 32px;

    &--smaller {
        @extend .heading;
        font-size: 24px;
        font-weight: 600;
    }
}
