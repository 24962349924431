.driver-form {
    &__container {
        width: 100%;
        margin: 15px 0;

        display: flex;
        flex-direction: column;
    }

    &__name-section,
    &__phone-section,
    &__config-section {
        display: flex;
        justify-content: space-between;

        margin-bottom: 20px;

        &--mobile {
            display: flex;
            flex-direction: column;
        }
    }

    &__config-section {
        align-items: flex-end;
    }

    &__switch {
        &__container {
            display: flex;
            align-items: center;

            &--mobile {
                @extend .driver-form__switch__container;
                justify-content: flex-end;

                margin-top: 20px;
            }
        }
    }
}
