.labelled-input {
    &__container {
        width: 100%;
        margin-right: 25px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__validation-error {
        @extend .input-label;
        font-size: 10px !important;
        font-style: italic;
        color: red !important;
    }
}
