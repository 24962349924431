.tier-rates {
    &__container {
        width: calc((100% - 120px) / 3);
        margin: 20px;
        padding: 12px;
        box-sizing: border-box;

        border: 3px solid $color-xpress-blue;

        &--mobile {
            @extend .tier-rates__container;
            width: auto;
        }
    }

    &__name {
        display: flex;
        justify-content: center;

        @extend .heading--smaller;
    }

    &__add {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 80%;
    }

    &__rate-line {
        display: flex;
        justify-content: space-between;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    &__audit {
        &__container {
            display: flex;
            justify-content: flex-end;

            width: 100%;
        }

        &__icon {
            position: absolute;
            top: -50px;

            font-size: 22px;
            color: #0e76bc;

            cursor: pointer;
        }
    }

    &__history {
        &__container {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        &__details {
            margin-left: 10px;
            color: $color-xpress-blue--faint;
            font-style: italic;

            cursor: help;
        }
    }
}
