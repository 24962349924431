.table-search-and-filter {
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 5px;

        border-bottom: 1px solid #e7e7e7;
        background-color: #fafafa;

        &--mobile {
            display: flex;
            flex-direction: column;

            padding: 5px;

            border-bottom: 1px solid #e7e7e7;
            background-color: #fafafa;
        }
    }

    &__active-filter {
        &__container {
            display: flex;
        }
    }
}

.table-search-and-filter__container--mobile > span {
    margin-bottom: 15px !important;
}
