.create-special-payroll {
    &__spinner {
        position: absolute;
        top: 20px;
        right: 16px;
    }

    &__driver-select {
        &__container {
            display: flex;
            flex-direction: column;
        }
    }

    &__truck-select {
        &__container {
            display: flex;
            flex-direction: column;

            width: 100%;
            margin-top: 25px;
        }

        &__heading {
            font-weight: bold;
        }

        &__no-trucks {
            display: flex;
            justify-content: center;
            width: 100%;

            color: $color-disabled;
            font-style: italic;
        }
    }

    &__footer {
        &__container {
            display: flex;
            justify-content: flex-end;
        }

        &__generate {
            @extend .standard-button;

            &--disabled {
                @extend .standard-button--disabled;
            }
        }

        &__cancel {
            @extend .standard-button--empty;
            margin-right: 15px;
        }
    }

    &__payroll-preview {
        &__container {
            display: flex;
            flex-direction: column;

            width: 100%;
            margin-top: 25px;
        }

        &__line-break {
            border-top: 1px $color-disabled solid;
            margin: 5px 0;
            padding: 10px 0;
            box-sizing: border-box;
        }

        &__totals-container {
            display: flex;
            justify-content: space-evenly;

            width: 100%;

            font-family: 'Varela Round';
            font-size: 24px;
        }

        &__services-container {
            width: 100%;
            margin-top: 15px;
        }

        &__totals-label {
            margin-right: 5px;
            font-weight: bold;
        }
    }
}

.create-special-payroll__truck-select__container .ant-checkbox-group-item {
    width: 100%;
}
