.notifications {
    &__container {
        display: flex;
        flex-direction: column;

        padding: 15px;
        box-sizing: border-box;
    }

    &__row {
        &__container {
            display: flex;
            flex-direction: column;
            position: relative;

            box-sizing: border-box;
            margin: 10px 0;
            border-top: 1px solid $color-xpress-blue--faint;
            padding-top: 10px;

            &:first-child {
                margin: 0;
                padding-top: 0;
                border-top: none;
            }
        }

        &__title {
            display: flex;
            justify-content: center;
            width: 100%;

            font-weight: bold;
            font-style: italic;

            color: $color-xpress-blue;
        }

        &__details {
            display: flex;
        }

        &__detail {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }

        &__dismiss {
            position: absolute;
            right: 0;
            bottom: 25px;
        }
    }
}
