$bgcolor-success: #99c24d;
$bgcolor-warning: #ffa630;
$bgcolor-error: #d93c20;
$bgcolor-info: #66b0ea;

.modal {
    &__header {
        &__container {
            padding: 16px 24px;
            position: relative;
            overflow: hidden;

            color: #fff;

            &--success {
                @extend .modal__header__container;
                background-color: $bgcolor-success;
            }

            &--warning {
                @extend .modal__header__container;
                background-color: $bgcolor-warning;
            }

            &--error {
                @extend .modal__header__container;
                background-color: $bgcolor-error;
            }

            &--info {
                @extend .modal__header__container;
                background-color: $bgcolor-info;
            }
        }

        &__title {
            margin-left: 60px;

            color: #fff;
            font-size: 20px;
            font-weight: 400;
        }
    }
}

// ANTD OVERRIDES
.ant-modal-header {
    padding: 0;
}

.ant-btn {
    border: none !important;
}

.ant-btn.ant-btn-default {
    color: #1e88d8;
    font-family: 'Arial';
}

.ant-btn.ant-btn-primary {
    border-radius: 4px;
    font-family: 'Arial';
}
