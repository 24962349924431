.address-form {
    &__line {
        display: flex;
        justify-content: space-between;

        margin-bottom: 0;

        &--mobile {
            display: flex;
            flex-direction: column;
        }
    }
}
