.email-config-table {
    &__outer-container {
        padding: 20px;
    }

    &__container {
        padding: 20px;

        border: 3px solid $color-xpress-blue;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-family: 'Varela Round';
        font-size: 28px;

        &--mobile {
            @extend .email-config-table__title;
            font-size: 22px;
        }
    }

    &__helper-text {
        display: flex;
        justify-content: center;

        font-style: italic;
    }

    &__table {
        display: flex;
        justify-content: center;

        width: 100%;
        margin-top: 30px;
    }
}
