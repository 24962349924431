.settings {
    &__tab-content {
        &__container {
            width: 100%;
            padding: 20px;
        }
    }

    &__credentials {
        &__component {
            &__container {
                padding: 20px;
                margin-bottom: 30px;

                border: 3px solid $color-xpress-blue;
            }

            &__title {
                font-family: 'Varela Round';
                font-size: 28px;

                &--mobile {
                    @extend .settings__credentials__component__title;
                    font-size: 22px;
                }
            }
        }
    }
}

// ANTD OVERRIDES
.ant-tabs-nav-wrap {
    margin-left: 20px !important;
}

.ant-tabs-tab {
    padding: 15px 50px !important;
    border-radius: 15px 15px 0 0 !important;

    font-family: 'Arial';

    background-color: $color-disabled !important;
    color: white !important;
}

.ant-tabs-tab-active {
    background-color: $color-xpress-blue !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
}
