// COLORS
$color-xpress-blue: #0e76bc;
$color-xpress-blue--faint: #88bee2;
$color-xpress-red: #ee2824;
$color-xpress-green: #4cee24;
$color-xpress-yellow: #eecc24;
$color-disabled: #b3b3b3;

// SIZES
$navbar-height: 70px;
