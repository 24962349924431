.tabs {
    &__container {
        display: flex;

        width: 100%;

        &--vertical {
            @extend .tabs__container;
            flex-direction: column;
        }

        &--mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;

            margin: 5px 20px;
        }
    }
}
