.sales-report {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__data {
        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;

            margin-top: 25px;
            margin-bottom: 20px;
            padding: 5px 25px;

            border: 2px $color-disabled solid;
            border-radius: 6px;

            &--full-width {
                @extend .sales-report__data__container;
                width: 95%;
            }
        }

        &__grouped-rows {
            &__container {
                display: flex;
                flex-direction: column;

                margin-top: 25px;
            }

            &__row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;

                font-size: 20px;
                font-weight: 500;

                cursor: pointer;

                &:hover {
                    color: $color-xpress-blue;
                }

                &--key {
                    margin-right: 100px;
                }
            }

            &__info-text {
                margin-top: 20px;
                font-size: 16px;
                font-style: italic;

                &__link {
                    @extend .sales-report__data__grouped-rows__info-text;
                    text-decoration: underline;
                    color: $color-xpress-blue;

                    cursor: pointer;
                }
            }
        }
    }
}
