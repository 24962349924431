.data-entry-navigation {
    &__container {
        display: flex;
        justify-content: space-between;

        margin-top: 15px;

        &--left,
        &--right {
            @extend .data-entry-navigation__container;
            width: 49%;

            &--mobile {
                width: 100%;
            }
        }

        &--mobile {
            width: 100%;
        }
    }

    &__select-menu,
    &__search-menu {
        &__container {
            display: flex;

            width: 49%;

            &--mobile {
                width: 100%;
            }
        }
    }

    &__arrow-menu {
        &__container {
            display: flex;
            justify-content: flex-end;

            width: 100%;

            font-family: 'Arial';
            font-size: 24px;
            color: #535353;
        }
    }
}
