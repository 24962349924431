.add-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 80px;
    height: 80px;

    background-color: $color-xpress-blue;
    color: white;
    border-radius: 50%;

    font-family: 'Varela Round';
    font-size: 60px;
    line-height: 60px;
    font-weight: 500;

    cursor: pointer;

    &:hover {
        color: rgb(0, 195, 255);
    }
}

.mobile-add-button {
    @extend .add-button;

    width: 45px;
    height: 45px;

    font-size: 40px;
    line-height: 40px;
    font-weight: 400;
}
