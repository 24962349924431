.employee-payroll-details {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        font-family: 'Varela Round';
        font-size: 24px;
        color: #535353;
    }

    &__info-text {
        @extend .input-label;
        font-style: italic;
        text-align: center;
    }

    &__services {
        &__container {
            @extend .employee-payroll-details__container;
            border: 1px solid $color-xpress-blue--faint;

            margin-bottom: 5px;
            padding: 10px;
            box-sizing: border-box;
        }

        &__service-detail-container {
            display: flex;
            justify-content: space-between;

            width: 100%;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__column {
        display: flex;
        justify-content: flex-start;
    }
}
